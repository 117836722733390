<template>
  <div class="section">
    <div class="base bg-type">
      <img src="../assets/bg.jpg" class="hidden" />
      <div class="header">全站iOS客户端 － 安装说明</div>
      <div class="contains">
        <div class="step step-1">
          <div class="step-num">第一步</div>
          <div class="step-text">
            下载 【TestFlight】<br />然后<span>返回本页面</span>，操作第二步
          </div>
          <div class="icon bg-type"></div>
          <br />
          <div class="button bg-type" @click="downloadTestFlight"></div>
        </div>
        <div class="step step-2">
          <div class="step-num">第二步</div>

          <div class="step-text">
            下载 <span>【全站iOS客户端】</span><br />安装好后，点开始测试
          </div>
          <div class="icon bg-type"></div>
          <br />

          <div v-if="target" class="button bg-type" @click="downloadLink"></div>
        </div>
      </div>
      <!-- <div class="cover"></div> -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Download",
  data() {
    return { inTouch: false, target: null };
  },
  methods: {
    openWindow(url, targetType = "_blank", id = "open") {
      if (document.getElementById(id)) {
        document.body.removeChild(document.getElementById(id));
      }
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", targetType);

      a.setAttribute("id", id);
      document.body.appendChild(a);
      a.click();
    },
    downloadTestFlight() {
      const url = "https://apps.apple.com/us/app/testflight/id899247664";
      window.open(url, "_blank");
    },
    downloadLink() {
      const url = this.target;
      // window.open(url, "_blank");
      this.openWindow(url);
    },
  },
  async created() {
    const url = process.env.VUE_APP_LINK;

    const { data } = await axios.get(`https://${url}`);
    this.target = data.data;
  },
  mounted() {
    document.addEventListener(
      "touchstart",
      (event) => {
        if (event.touches.length > 1 || this.inTouch) {
          event.preventDefault();
        }
        this.inTouch = true;
      },
      { passive: false }
    );

    document.addEventListener(
      "touchend",
      (event) => {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
        this.inTouch = event.touches.length > 0;
      },
      false
    );
  },
};
</script>
<style lang="scss" scoped>
img.hidden {
  width: 100%;
  height: auto;
  visibility: hidden;
}

.base.bg-type {
  position: relative;
  width: 100%;
  background-image: url("~@/assets/bg.jpg");
  background-size: cover;
  height: auto;

  padding-bottom: 0;
  background-position: center;
  min-height: 100vh;
}
.cover {
  background-image: url("~@/assets/cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.header {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: auto;
  text-align: center;
  background-color: #f5f5f5;
  color: #131313;
  font-size: 4vw;
  line-height: 10vw;
  word-spacing: -1.5vw;
  font-weight: 600;
}
.contains {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: block;

  /*display: flex;
		    flex-direction: column;
		    align-items: center;
		    justify-content: space-evenly;*/
  height: 100%;
}
.step {
  width: 100%;
  text-align: center;
  height: 40%;
  position: absolute;
}
.step > .icon {
  margin-bottom: 2vw;
}
.step-num {
  font-size: 6.2vw;
  margin-bottom: 2.4vw;
  font-weight: 600;
}
.step-text {
  font-size: 4vw;
  margin-bottom: 3vw;
  font-weight: 400;
  color: #686868;
}
.step-text span {
  color: #f96501;
}
.step-1 {
  top: 10.3%;
}
.step-1 > .icon {
  width: 20%;
}
.step-1 > .icon::after {
  padding-bottom: 100%;
}
.step-1 > .button {
  width: 47%;
}
.step-1 > .button::after {
  padding-bottom: 23.29545454545455%;
}
.step-2 {
  top: 50.5%;
}
.step-2 > .step-text {
  margin-bottom: 6vw;
}
.step-2 > .icon {
  width: 30%;
  margin-bottom: 0vw;
}
.step-2 > .icon::after {
  padding-bottom: 100%;
}
.step-2 > .button {
  width: 46%;
}
.step-2 > .button::after {
  padding-bottom: 23.51274787535411%;
}
.bg-type,
.bg-type::after,
.bg-type::before {
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-type::after {
  content: "";
  position: relative;
  width: 100%;
  display: block;
}
.bg-type {
  height: auto;
  display: inline-block;
}
@media only screen and (max-device-pixel-ratio: 2.24),
  only screen and (max-resolution: 2.24dppx) {
  .step-1 > .icon::after {
    background-image: url("~@/assets/2x/TestFlight.png");
  }
  .step-1 > .button::after {
    background-image: url("~@/assets/2x/btn01.png");
  }
  .step-2 > .icon::after {
    background-image: url("~@/assets/2x/app.png");
  }
  .step-2 > .button::after {
    background-image: url("~@/assets/2x/btn02.png");
  }
}

@media only screen and (min-device-pixel-ratio: 2.25),
  only screen and (min-resolution: 2.25dppx) {
  .step-1 > .icon::after {
    background-image: url("~@/assets/3x/TestFlight.png");
  }
  .step-1 > .button::after {
    background-image: url("~@/assets/3x/btn01.png");
  }
  .step-2 > .icon::after {
    background-image: url("~@/assets/3x/app.png");
  }
  .step-2 > .button::after {
    background-image: url("~@/assets/3x/btn02.png");
  }
}
</style>
